import React from "react";

const Footer = () => {
  return (
    <>
{/*       <footer className="App-footer">
        <p className="footer-text">&copy; 2024 Stitch Switch. All Rights Reserved.</p>
      </footer> */}
    </>
  );
};

export default Footer;
